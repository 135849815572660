import { LoginData } from "./Site/admin/Login";
import { ServiceFormData } from "./Site/Form";
import { welcomeFormData } from "./Site/Welcome";

const API_BASE = "/api/";

export interface EmployeeType {
  Name: string;
  Mobile: string;
  EMail: string;
  Code: string;
}

export interface CompanyType {
  Name: string;
  Ansprechpartner: string;
  Adresse: string;
  Ort: string;
  PLZ: string;
  Telefon: string;
  EMail: string;
  Mitarbeiter: EmployeeType[];
  Code: string;
  ID: string;
}

export async function checkCode(
  req: welcomeFormData
): Promise<Error | CompanyType> {
  const res = await fetch(API_BASE + "code", {
    method: "POST",
    body: JSON.stringify(req)
  });
  console.log(res);

  if (!res.ok) {
    switch (res.status) {
      case 400:
        return new Error("Es ist ein interner Fehler aufgetreten");
      case 401:
        return new Error("Der Code ist falsch");
      default:
        return new Error("Unbekanter Fehler");
    }
  }
  const data = await res.json();
  return data as CompanyType;
}

export async function checkAdminLogin(req: LoginData): Promise<Error | null> {
  const res = await fetch(API_BASE + "admin/login", {
    method: "POST",
    body: JSON.stringify(req)
  });
  console.log(res);

  switch (res.status) {
    case 200:
      return null;
    case 400:
      return new Error("Es ist ein interner Fehler aufgetreten");
    case 401:
      return new Error("Der Benutzername oder das Passwort ist falsch");
    default:
      return new Error("Unbekanter Fehler");
  }
}

export async function submitData(req: ServiceFormData) {
  const res = await fetch(API_BASE + "submit_form", {
    method: "POST",
    body: JSON.stringify(req)
  });
  console.log(res);

  switch (res.status) {
    case 200:
      return "OK";
    case 500:
      throw new Error("Es ist ein interner Fehler aufgetreten");
    default:
      throw new Error("Unbekanter Fehler");
  }
}

export async function delCompany(id: string) {
  const res = await fetch(API_BASE + "admin/del_company", {
    method: "POST",
    body: JSON.stringify({ id: id })
  });
  console.log(res);

  switch (res.status) {
    case 200:
      return "OK";
    case 500:
      throw new Error("Es ist ein interner Fehler aufgetreten");
    default:
      throw new Error("Unbekanter Fehler");
  }
}

export async function setCompany(company: CompanyType) {
  const res = await fetch(API_BASE + "admin/set_company", {
    method: "POST",
    body: JSON.stringify(company)
  });
  console.log(res);

  switch (res.status) {
    case 200:
      return "OK";
    case 500:
      throw new Error("Es ist ein interner Fehler aufgetreten");
    default:
      throw new Error("Unbekanter Fehler");
  }
}
