import React, { useMemo, useState } from "react";
import { CellProps, Column, Row, useExpanded, useTable } from "react-table";
import Carousel, { Modal, ModalGateway, ViewType } from "react-images";
import { DateTime } from "luxon";

import CssBaseline from "@material-ui/core/CssBaseline";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ServiceFormData } from "../Form";
import { useAxios } from "use-axios-client";
import Nav from "./Nav";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  "@global tbody tr:nth-child(even)": {
    background: "rgba(0, 0, 0, 0.03)",
  },
  "@global tbody tr:hover": {
    backgroundColor: "#dcdcdc",
  },
  "@global tbody tr": {
    color: "#222",
  },
  "@global td": {
    borderLeft: "1px solid lightgrey",
    borderBottom: "0px !important",
  },
  "@global th": {
    borderRight: "1px solid lightgrey",
  },
  borderHead: {
    borderBottom: "2px solid black",
  },
  borderHeadField: {
    borderBottom: "1px solid rgba(224, 224, 224, 1);",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Table(props: {
  columns: Column<ServiceFormData>[];
  data: ServiceFormData[];
}) {
  const classes = useStyles();

  const columns = props.columns;
  const data = props.data;

  const { getTableProps, headerGroups, rows, prepareRow, visibleColumns } =
    useTable(
      {
        columns,
        data,
      },
      useExpanded
    );

  const getStyles = (props: any, { column }: any) => {
    console.log(column);
    if (column.id === "expander") {
      return [
        props,
        {
          style: {
            width: "35px",
          },
        },
      ];
    }
    return props;
  };

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead className={classes.borderHead}>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps(getStyles)}>
                {column.render("Header")}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // Use a React.Fragment here so the table markup is still valid
            <React.Fragment {...row.getRowProps()}>
              <TableRow>
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
              {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    <RenderRowSubComponent
                      row={row}
                      className={classes.paper}
                    ></RenderRowSubComponent>
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

function FormTable() {
  const columns = useMemo<Column<ServiceFormData>[]>(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }: CellProps<ServiceFormData>) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </span>
        ),
      },
      {
        Header: "Datum",
        accessor: "Timestamp",
        Cell: ({ value }: CellProps<ServiceFormData>) =>
          DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        Header: "Firma",
        columns: [
          {
            Header: "Name",
            accessor: "Firma.Name",
          },
          {
            Header: "Mitarbeiter",
            accessor: "Mitarbeiter.Name",
          },
        ],
      },
      {
        Header: "Gebäude",
        columns: [
          {
            Header: "Strasse",
            accessor: "Strasse",
          },
          {
            Header: "Nr",
            accessor: "Nr",
          },
          {
            Header: "PLZ",
            accessor: "PLZ",
          },
          {
            Header: "Ort",
            accessor: "Ort",
          },
        ],
      },
      {
        Header: "Zugang",
        columns: [
          {
            Header: "Zugang",
            accessor: "Zugang",
            Cell: ({ value, row }: CellProps<ServiceFormData>) => {
              if (value === "Abholen") {
                return "Abholden bei " + row.original.Abholen;
              }

              if (value === "MA") {
                return "Geöffnet durch " + row.original.Mitarbeiter.Name;
              }

              if (value === "MieterHauswart") {
                return (
                  <>
                    Mieter / Hauswart kontaktieren: <br />
                    {row.original.MHName}, {row.original.MHTelefon}
                  </>
                );
              }

              return value;
            },
          },
        ],
      },
      {
        Header: "Allgemein",
        columns: [
          {
            Header: "Termin",
            accessor: "Termin",
          },
          {
            Header: "Bemerkungen",
            accessor: "Bemerkungen",
          },
        ],
      },
    ],
    []
  );

  const { data, error, loading } = useAxios<ServiceFormData[]>({
    url: "/api/admin/get_data",
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{"Error: " + error.message}</div>;
  if (data !== undefined && data !== null && data.length !== 0) {
    //console.log(data);

    return (
      <div>
        <CssBaseline />
        <Table columns={columns} data={data} />
      </div>
    );
  } else {
    return (
      <div>
        <div>Keine Daten</div>
      </div>
    );
  }
}

function RenderRowSubComponent(props: {
  row: Row<ServiceFormData>;
  className?: any;
}) {
  const row = props.row;
  console.log(row);

  if (
    row.original.Arbeitsauftrag === undefined ||
    row.original.Arbeitsauftrag === null ||
    row.original.Arbeitsauftrag.length === 0
  ) {
    return <p>Das Element hat keinen Arbeitsauftrag</p>;
  }

  const Auftrag = row.original.Arbeitsauftrag.map((a, i) => {
    return (
      <Grid item>
        <Paper>
          <div
            style={{ display: "flex", flexDirection: "column", margin: "10px" }}
          >
            <Typography align="left" variant="subtitle1">
              {`Arbeitsauftrag ${i + 1}`}
            </Typography>
            <Typography align="left" variant="body2">
              Etage: {a.Etage}
            </Typography>
            <Typography align="left" variant="body2">
              WHG: {a.WHG}
            </Typography>
            <Typography align="left" variant="body2">
              Raum: {a.Raumbezeichnung}
            </Typography>

            <Typography align="left" variant="body2">
              Arbeit:{" "}
              {a.Arbeitsausführung === "Andere"
                ? a.Arbeitsausführung + ": " + a.Andere
                : a.Arbeitsausführung}
            </Typography>

            {a.Arbeitsausführung === "Platten auswechseln" ? (
              <Typography align="left" variant="body2">
                Platten
                {a.PlattenInfo === "Platten vorhanden"
                  ? " Info: " + a.PlattenInfo
                  : " Format: " + a.Plattenformat}
              </Typography>
            ) : null}

            <div>
              <GalleryComponent image={a.Bild} />
            </div>
          </div>
        </Paper>
      </Grid>
    );
  });

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid container spacing={3} className={props.className}>
        {Auftrag}
      </Grid>
    </div>
  );
}

function GalleryComponent(props: { image: string[] }) {
  const [lighboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = (index: number) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        maxWidth: "296px",
        flexWrap: "wrap",
      }}
    >
      {props.image.map((b, i) => (
        <div style={{ margin: "10px" }}>
          <img
            src={b}
            alt=""
            style={{
              width: 128,
              cursor: "zoom-in",
            }}
            onClick={() => openLightbox(i)}
          ></img>
        </div>
      ))}
      <ModalGateway>
        {lighboxOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={props.image.map((i): ViewType => ({ source: i }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

function Data() {
  return (
    <>
      <Nav />
      <FormTable />
    </>
  );
}

export default Data;
