import React, { useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import logoClean from "./Dietschweiler_Logo_clean.png";
import { useCompanyAuth } from "../auth";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 340,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      //marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    header: {
      textAlign: "center",
      background: "#212121",
      color: "#fff",
    },
    card: {
      marginTop: theme.spacing(10),
    },
    media: {
      height: 200,
      backgroundColor: "#373737",
      backgroundSize: "contain",
    },
  })
);

export interface welcomeFormData {
  code: string;
}

function Welcome() {
  let history = useHistory();
  const classes = useStyles();
  let auth = useCompanyAuth();
  const { register, handleSubmit, errors, reset } = useForm();
  const [err, setErr] = useState("");

  //update Webseite
  serviceWorkerRegistration.update();

  const onSubmit = async (data: welcomeFormData) => {
    console.log(data);

    const res = await auth?.signin(data);

    if (res === null) {
      history.push("/form");
    } else if (res !== undefined) {
      console.log(res);
      setErr(res);
      reset();
    } else {
      console.log("res undefined");
      setErr("Es ist ein unbekanter Fehler aufgetreten");
    }

    // checkCode(data)
    //   .then(() => {
    //     history.push("/form");
    //     // TODO auth
    //   })
    //   .catch(err => {
    //     console.log(err.message);
    //     setErr(err.message);
    //     reset();
    //   });
  };

  console.log(auth?.company);
  console.log(errors);

  return (
    <div>
      <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image={logoClean}
            title="Dietschweiler AG"
          />
          <CardContent>
            <div>
              <Typography variant="caption" align="center">
                Serviceportal
              </Typography>
              <p>
                Um einen Auftrag zu erfassen, melden sie sich mit ihrem Firmen
                oder Mitarbeiter-Code an.
              </p>
              <TextField
                error={errors.code !== undefined}
                fullWidth
                id="code"
                name="code"
                type="password"
                autoComplete="current-password"
                label="Firmen/Mitarbeiter-Code"
                placeholder="Code"
                margin="dense"
                inputRef={register({
                  required:
                    "Bitte geben sie den Firmen oder Mitarbeiter-Code an.",
                })}
                autoFocus
                helperText={errors.code?.message}
              />
            </div>
            {err ? (
              <Typography color="error" variant="subtitle1">
                {err}
              </Typography>
            ) : (
              <></>
            )}
          </CardContent>
          <CardActions>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.loginBtn}
            >
              Login
            </Button>
          </CardActions>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px",
            }}
          >
            <Link
              to="/admin"
              style={{ color: "grey", textDecoration: "underline" }}
            >
              <Typography variant="caption">Admin Seite</Typography>
            </Link>
          </div>
        </Card>
      </form>
    </div>
  );
}

export default Welcome;
