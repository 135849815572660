import { createContext, useContext, useState } from "react";
import { checkAdminLogin, checkCode, CompanyType } from "./api";
import { LoginData } from "./Site/admin/Login";
import { welcomeFormData } from "./Site/Welcome";

export interface CompanyAuth {
  company: CompanyType | null;
  signin: (code: welcomeFormData) => Promise<string | null>;
}

export const CompanyAuthContext = createContext<CompanyAuth | null>(null);

export function useCompanyAuth() {
  return useContext(CompanyAuthContext);
}

export function useProvideAuth(): CompanyAuth {
  const [company, setCompany] = useState<CompanyType | null>(null);

  const signin = async (data: welcomeFormData) => {
    try {
      const c = await checkCode(data);
      if (c instanceof Error) {
        return c.message;
      }
      setCompany(c);
      return null;
    } catch (err) {
      setCompany(null);
      return "Internal Error";
    }
  };

  // const signout = cb => {
  //   return fakeAuth.signout(() => {
  //     setUser(null);
  //     cb();
  //   });
  // };

  return {
    company,
    signin
    // signout
  };
}

export interface UserAuth {
  User: string | null;
  signin: (data: LoginData) => Promise<string | null>;
}

export const UserAuthContext = createContext<UserAuth | null>(null);

export function useUserAuth() {
  return useContext(UserAuthContext);
}

export function useProvideUserAuth(): UserAuth {
  const [User, setUser] = useState<string | null>(null);

  const signin = async (data: LoginData) => {
    try {
      const c = await checkAdminLogin(data);
      if (c instanceof Error) {
        return c.message;
      }
      setUser(data.user);
      return null;
    } catch (err) {
      setUser(null);
      return "Internal Error";
    }
  };

  // const signout = cb => {
  //   return fakeAuth.signout(() => {
  //     setUser(null);
  //     cb();
  //   });
  // };

  return {
    User,
    signin
    // signout
  };
}
