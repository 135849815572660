import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "nowrap",
      width: "100%",
      height: "60px",
      alignItems: "center",
      //background: "#212121"
      background: "black",
    },
    main: {
      //marginTop: theme.spacing(2),
      flexGrow: 1,
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      textAlign: "center",
      color: "#fff",
      height: "100%",
      padding: "5px 10px 5px 10px",
      margin: "0 10px 0 10px",
      display: "block",
      textDecoration: "none",
    },
    logo: {
      color: "white",
      marginLeft: "15px",
    },
    active: {
      color: "black",
      backgroundColor: "white",
    },
  })
);

const Nav = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <Typography variant="h4" align="center">
          Dietschweiler
        </Typography>
      </div>
      <div className={classes.main}>
        <div>
          <NavLink
            to="/admin/data"
            exact
            activeClassName={classes.active}
            className={classes.button}
          >
            <Typography variant="h5" align="center">
              Daten
            </Typography>
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/admin/company"
            exact
            activeClassName={classes.active}
            className={classes.button}
          >
            <Typography variant="h5" align="center">
              Firmen
            </Typography>
          </NavLink>
        </div>
        {/* <div>
          <NavLink
            to="/admin/users"
            exact
            activeClassName={classes.active}
            className={classes.button}
          >
            <Typography variant="h5" align="center">
              Admin
            </Typography>
          </NavLink>
        </div> */}
      </div>
      <div>User</div>
    </div>
  );
};

export default Nav;
