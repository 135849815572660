import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useUserAuth } from "../../auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 400,
      margin: `${theme.spacing(0)} auto`
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1
    },
    header: {
      textAlign: "center",
      background: "#212121",
      color: "#fff"
    },
    card: {
      marginTop: theme.spacing(10)
    }
  })
);

export interface LoginData {
  user: string;
  password: string;
}

const Login = () => {
  let history = useHistory();
  const classes = useStyles();
  let auth = useUserAuth();
  const { register, handleSubmit, errors } = useForm();
  const [err, setErr] = useState("");
  const onSubmit = async (data: LoginData) => {
    console.log(data);

    const res = await auth?.signin(data);

    if (res === null) {
      history.push("/admin/data");
    } else if (res !== undefined) {
      console.log(res);
      setErr(res);
    } else {
      console.log("res undefined");
      setErr("Es ist ein unbekanter Fehler aufgetreten");
    }
  };

  return (
    <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
      <Card className={classes.card}>
        <CardHeader className={classes.header} title="Administration" />
        <CardContent>
          <div>
            <TextField
              error={errors.user !== undefined}
              fullWidth
              id="user"
              name="user"
              type="text"
              label="Benutzername"
              placeholder="Benutzername"
              autoComplete="username"
              margin="normal"
              inputRef={register({
                required: "Bitte geben sie einen Benutzernamen an."
              })}
              autoFocus
              helperText={errors.user?.message}
            />
            <TextField
              error={errors.password !== undefined}
              fullWidth
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              autoComplete="current-password"
              margin="normal"
              inputRef={register({
                required: "Bitte geben sie ein Passwort an."
              })}
              helperText={errors.password?.message}
            />
          </div>
          {err ? <h4>{err}</h4> : <></>}
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="secondary"
            className={classes.loginBtn}
          >
            Login
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default Login;
