import React from "react";
import CompanyTable from "./CompanyData";
import Nav from "./Nav";

const Company = () => {
  return (
    <div>
      <Nav></Nav>
      <CompanyTable></CompanyTable>
    </div>
  );
};

export default Company;
