import { Box, Fab, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  IUploadParams,
  StatusValue
} from "react-dropzone-uploader";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  dropZone: {
    paddingLeft: "12px",
    paddingRight: "12px"
  },
  marginNormal: {
    marginTop: "16px",
    marginBottom: "8px"
  },
  uploadButton: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  imageContainer: {
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3
    },
    "&:hover $removeButton": {
      opacity: 1
    },
    margin: "0px 10px 0px 10px"
  },
  image: {
    height: 100,
    width: "initial",
    maxWidth: "100%",
    color: theme.palette.text.primary,
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    boxSizing: "border-box",
    boxShadow: "rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px",
    borderRadius: theme.shape.borderRadius,
    zIndex: 5,
    opacity: 1
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    opacity: 0,
    top: theme.spacing(-1),
    right: theme.spacing(-1),
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1
    }
  },
  dropZoneMain: {
    minHeight: 100,
    maxHeight: 200,
    backgroundColor: theme.palette.background.paper,
    border: "dashed",
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    boxSizing: "border-box",
    cursor: "pointer",
    overflow: "hidden",
    marginTop: "10px",
    marginBottom: "10px"
  }
}));

// add type defs to custom LayoutComponent prop to easily inspect props passed to injected components
const Layout = ({
  input,
  previews,
  dropzoneProps,
  files,
  extra: { maxFiles }
}: ILayoutProps) => {
  return (
    <>
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      <Grid spacing={0} container={true}>
        {previews}
      </Grid>
    </>
  );
};

const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
  const classes = useStyles();

  const { name, percent, status, previewUrl } = meta;
  const { remove } = fileWithMeta;

  console.log(meta);
  return (
    <>
      <Grid xs={4} item={true} key={name} className={classes.imageContainer}>
        <img
          className={classes.image}
          role="presentation"
          src={previewUrl}
          alt=""
        />
        <Fab
          onClick={remove}
          aria-label="Delete"
          className={classes.removeButton}
        >
          <DeleteIcon />
        </Fab>
        {/* <CircularProgressWithLabel value={percent} /> */}
        <LinearProgress variant="determinate" value={percent} />
        {status}
      </Grid>
    </>
  );
};

export function FormImageInput(props: { onChange: (...event: any[]) => void }) {
  const handleSubmit: IDropzoneProps["onSubmit"] = (files, allFiles) => {
    console.log(files.map(f => f.meta));
    allFiles.forEach(f => f.remove());
  };

  const getUploadParams = async ({
    file
  }: IFileWithMeta): Promise<IUploadParams> => {
    const fileUrl = await (await fetch("/api/upload")).text();
    const downloadURL = fileUrl.split("?")[0];
    return {
      method: "put",
      body: file,
      url: fileUrl,
      meta: { fileUrl: downloadURL }
    };
  };

  const handleChangeStatus = (
    file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[]
  ) => {
    console.log(status);
    console.log(file);
    console.log(allFiles);

    if (status === "done" || status === "removed") {
      console.log("done ---");
      const f = allFiles.map(f => {
        const m: any = f.meta;
        return m.fileUrl;
      });
      console.log(f);
      props.onChange(f);
      console.log("---");
    }
  };
  const classes = useStyles();
  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      getUploadParams={getUploadParams}
      LayoutComponent={Layout}
      PreviewComponent={props => <Preview {...props} />}
      InputComponent={Input}
      onSubmit={handleSubmit}
      autoUpload
      maxFiles={3}
      classNames={{ dropzone: classes.dropZoneMain }}
      styles={{
        dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
        inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {})
      }}
      inputContent={(files, extra) =>
        extra.reject ? "Nur Bilder sind erlaubt" : "Bilder hier hochladen"
      }
      accept="image/*"
    />
  );
}

const Input = ({ accept, onFiles, files }: IInputProps) => {
  const classes = useStyles();
  return (
    <Box flexDirection="column">
      <label style={{ display: "block" }}>
        <Fab
          color="secondary"
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
          className={classes.uploadButton}
        >
          <AddIcon />
          {files.length > 0 ? "Weitere Bilder hochladen" : "Bild hochladen"}
        </Fab>
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={e => {
            const tmpFiles = e.currentTarget.files;
            const Files: File[] = [];
            if (tmpFiles !== null) {
              for (let i = 0; i < tmpFiles.length; i++) {
                const f = tmpFiles.item(i);
                if (f !== null) {
                  Files.push(f);
                }
              }
              onFiles(Files);
            }
          }}
        />
      </label>
      <label style={{ display: "block" }}>
        <Fab
          color="secondary"
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
          className={classes.uploadButton}
        >
          <CameraAltIcon />
          {files.length > 0 ? "Weitere Bilder Aufnehmen" : "Bild Aufnehmen"}
        </Fab>
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={e => {
            const tmpFiles = e.currentTarget.files;
            const Files: File[] = [];
            if (tmpFiles !== null) {
              for (let i = 0; i < tmpFiles.length; i++) {
                const f = tmpFiles.item(i);
                if (f !== null) {
                  Files.push(f);
                }
              }
              onFiles(Files);
            }
          }}
          capture
        />
      </label>
    </Box>
  );
};
