import React, { useMemo } from "react";
import {
  CellProps,
  Column,
  HeaderProps,
  Row,
  useExpanded,
  useTable,
} from "react-table";
import { SnackbarProvider, useSnackbar } from "notistack";

import CssBaseline from "@material-ui/core/CssBaseline";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useAxios } from "use-axios-client";
import { Button, Fab, Grid, makeStyles, Paper } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CompanyType, delCompany, EmployeeType, setCompany } from "../../api";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  "@global tbody tr:nth-child(even)": {
    background: "rgba(0, 0, 0, 0.03)",
  },
  "@global tbody tr:hover": {
    backgroundColor: "#dcdcdc",
  },
  "@global tbody tr": {
    color: "#222",
  },
  "@global td": {
    borderLeft: "1px solid lightgrey",
    borderBottom: "0px !important",
  },
  "@global th": {
    borderRight: "1px solid lightgrey",
  },
  borderHead: {
    borderBottom: "2px solid black",
  },
  borderHeadField: {
    borderBottom: "1px solid rgba(224, 224, 224, 1);",
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: "28px",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  edit,
}: CellProps<CompanyType>) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (edit[index] === true) {
    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  } else {
    return value;
  }
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

function Table(props: {
  columns: Column<CompanyType>[];
  data: CompanyType[];
  refetch: () => Promise<void>;
  updateMyData: (rowIndex: any, columnId: any, value: any) => void;
  skipPageReset: boolean;
  edit: { [key: number]: boolean };
  addNewEmployee: (id: string) => void;
}) {
  const classes = useStyles();

  const columns = props.columns;
  const data = props.data;
  const skipPageReset = props.skipPageReset;
  const updateMyData = props.updateMyData;
  const edit = props.edit;

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow, visibleColumns } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
        edit,
      },
      useExpanded
    );

  const getStyles = (props: any, { column }: any) => {
    if (column.id === "expander") {
      return [
        props,
        {
          style: {
            width: "35px",
          },
        },
      ];
    }
    if (column.id === "aktionen") {
      return [
        props,
        {
          style: {
            width: "91px",
          },
        },
      ];
    }
    return props;
  };

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead className={classes.borderHead}>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps(getStyles)}>
                {column.render("Header")}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // Use a React.Fragment here so the table markup is still valid
            <React.Fragment key={i}>
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
              {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
              {CheckExtended(row) ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    <RenderRowSubComponent
                      row={row}
                      className={classes.paper}
                      refetch={props.refetch}
                      addNewEmployee={props.addNewEmployee}
                      originalData={data}
                    ></RenderRowSubComponent>
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

function CheckExtended(row: Row<CompanyType>): boolean {
  if (row.isExpanded) {
    return true;
  }
  if (row.original.Mitarbeiter?.length === 1) {
    if (
      row.original.Mitarbeiter[0].Name === "" &&
      row.original.Mitarbeiter[0].Mobile === "" &&
      row.original.Mitarbeiter[0].EMail === ""
    ) {
      return true;
    }
  }
  return false;
}

function CompanyTable() {
  const { data, error, loading, refetch } = useAxios<CompanyType[]>({
    url: "/api/admin/get_company",
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{"Error: " + error.message}</div>;

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <CompanyTableRenderer data={data} refetch={refetch} />
    </SnackbarProvider>
  );
}

function CompanyTableRenderer(props: {
  data: CompanyType[] | undefined;
  refetch: () => Promise<void>;
}) {
  const refetch = props.refetch;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState(props.data);
  //const [originalData] = React.useState(data);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [edit, setEdit] = React.useState<{ [key: number]: boolean }>({});

  const [newData, setNewData] = React.useState(false);

  const addNewData = () => {
    if (newData) {
      // skip if already executed
      return;
    }

    let emptyData: CompanyType[];
    if (data === undefined || data === null || data.length === 0) {
      emptyData = [];
    } else {
      emptyData = data;
    }

    emptyData.push({
      Name: "",
      Ansprechpartner: "",
      Adresse: "",
      Ort: "",
      PLZ: "",
      Telefon: "",
      EMail: "",
      Mitarbeiter: [],
      Code: "",
      ID: "",
    });
    console.log(emptyData);
    setData([...emptyData]);
    setNewData(true);
  };

  //  console.log(data);

  const updateMyData = (rowIndex: any, columnId: any, value: any) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  const addNewEmployee = (id: string) => {
    // if (newData) {
    //   // skip if already executed
    //   return;
    // }

    const employee = {
      Name: "",
      Mobile: "",
      EMail: "",
      Code: "",
    };

    if (data === undefined) {
      return;
    }

    const dataNew = data.map((c) => {
      if (id === c.ID) {
        c.Mitarbeiter.push(employee);
      }
      return c;
    });

    setData([...dataNew]);
  };

  const columns = useMemo<Column<CompanyType>[]>(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }: CellProps<CompanyType>) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </span>
        ),
      },
      {
        Header: "Name",
        accessor: "Name",
        // Cell: ({ row, value }: CellProps<CompanyType>) => {
        //   // We need to keep and update the state of the cell normally
        //   //  const [value, setValue] = React.useState(initialValue);
        //   console.log(value);
        //   setName(value);
        //   console.log(name);

        //   const onChange = (e: any) => {
        //     setName(e.target.value);
        //   };

        //   // If the initialValue is changed external, sync it up with our state
        //   React.useEffect(() => {
        //     setName(value);
        //   }, [value]);

        //   return <input value={name} onChange={onChange} />;
        // }
      },
      {
        Header: "Ansprechpartner",
        accessor: "Ansprechpartner",
      },
      {
        Header: "Adresse",
        accessor: "Adresse",
      },
      {
        Header: "Ort",
        accessor: "Ort",
      },
      {
        Header: "PLZ",
        accessor: "PLZ",
      },
      {
        Header: "Telefon",
        accessor: "Telefon",
      },
      {
        Header: "EMail",
        accessor: "EMail",
      },
      {
        Header: "Code",
        accessor: "Code",
      },
      {
        id: "aktionen",
        Header: "Aktionen",
        Cell: ({ row }: CellProps<CompanyType>) => {
          const del = (e: any) => {
            delCompany(row.original.ID).then(() => {
              refetch();
            });
          };
          const save = (e: any) => {
            if (row.original.Name === "" || row.original.Code === "") {
              enqueueSnackbar("Bitte Felder ausfüllen", {
                variant: "error",
              });
              return;
            }

            setCompany(row.original).then(() => {
              refetch();
            });
          };

          const enableEdit = (index: number) => {
            setEdit({ ...edit, [index]: true });
          };

          if (row.original.ID === "" && edit[row.index] !== true) {
            // new data
            enableEdit(row.index);
          }

          return (
            <div
              style={{
                display: "flex",
                flexFlow: "nowrap",
              }}
            >
              {edit[row.index] ? (
                <SaveIcon
                  onClick={save}
                  style={{ cursor: "pointer" }}
                ></SaveIcon>
              ) : (
                <EditIcon
                  //onClick={() => updateEdit(row.index)}
                  onClick={() => enableEdit(row.index)}
                  style={{ cursor: "pointer" }}
                ></EditIcon>
              )}
              {row.original.ID !== "" ? (
                <DeleteIcon
                  onClick={del}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                ></DeleteIcon>
              ) : null}
            </div>
          );
        },
      },
    ],
    [edit, enqueueSnackbar, refetch]
  );

  // console.log(edit);

  if (data === undefined || data === null || data.length === 0) {
    return (
      <div>
        <CssBaseline />
        <div>Kein Daten</div>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => addNewData()}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }

  return (
    <div>
      <CssBaseline />
      <Table
        columns={columns}
        data={data}
        refetch={refetch}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        edit={edit}
        addNewEmployee={addNewEmployee}
      />
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={() => addNewData()}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

function RenderRowSubComponent(props: {
  row: Row<CompanyType>;
  className?: any;
  refetch: () => Promise<void>;
  addNewEmployee: (id: string) => void;
  originalData: CompanyType[];
}) {
  const row = props.row;
  console.log(row);

  if (
    row.original.Mitarbeiter === undefined ||
    row.original.Mitarbeiter === null ||
    row.original.Mitarbeiter.length === 0
  ) {
    return (
      <div
        style={{
          marginLeft: "60px",
          display: "flex",
          flexDirection: "row",
          flexFlow: "nowrap",
          alignItems: "center",
        }}
      >
        <p>Die Firma hat keine Mitarbeiter</p>{" "}
        <Button
          variant="contained"
          color="primary"
          size="small"
          //className={classes.button}
          startIcon={<AddIcon />}
          style={{ marginLeft: "20px" }}
          onClick={() => props.addNewEmployee(row.original.ID)}
        >
          Neu
        </Button>
      </div>
    );
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid container spacing={3} className={props.className}>
        <Grid item>
          <Paper>
            <EmployeeTable
              row={row}
              refetch={props.refetch}
              originalData={props.originalData}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

function EmployeeTable(props: {
  row: Row<CompanyType>;
  refetch: () => Promise<void>;
  originalData: CompanyType[];
}) {
  const refetch = props.refetch;
  let original = props.row.original;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState(props.row.original.Mitarbeiter);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [edit, setEdit] = React.useState<{ [key: number]: boolean }>({});
  const [newData, setNewData] = React.useState(false);

  const addNewData = () => {
    if (newData) {
      // skip if already executed
      return;
    }
    data.push({
      Name: "",
      Mobile: "",
      EMail: "",
      Code: "",
    });
    setData([...data]);
    setNewData(true);
  };

  const updateMyData = (rowIndex: any, columnId: any, value: any) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  const columns = React.useMemo<Column<EmployeeType>[]>(
    () => [
      {
        id: "Mitarbeiter",
        Header: ({ value }: HeaderProps<EmployeeType>) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexFlow: "nowrap",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: 500, flexGrow: 1, fontSize: "1.2em" }}>
              Mitarbeiter
            </div>

            <Button
              variant="contained"
              color="primary"
              size="small"
              //className={classes.button}
              startIcon={<AddIcon />}
              style={{ float: "right" }}
              onClick={() => addNewData()}
            >
              Neu
            </Button>
          </div>
        ),
        columns: [
          {
            Header: "Name",
            accessor: "Name", // accessor is the "key" in the data
          },
          {
            Header: "Mobile",
            accessor: "Mobile",
          },
          {
            Header: "EMail",
            accessor: "EMail",
          },
          {
            Header: "Code",
            accessor: "Code",
          },
          {
            Header: "Aktionen",
            Cell: ({ row }: CellProps<EmployeeType>) => {
              const del = (e: any) => {
                original.Mitarbeiter = original.Mitarbeiter.filter(function (
                  e
                ) {
                  return e.Name !== row.original.Name;
                });

                setCompany(original).then(() => {
                  refetch();
                });
                //TODO: error tost
              };

              const save = (e: any) => {
                if (
                  row.original.Name === "" ||
                  row.original.EMail === "" ||
                  row.original.Mobile === ""
                ) {
                  enqueueSnackbar("Bitte Felder ausfüllen", {
                    variant: "error",
                  });
                  return;
                }

                // check if code is Unique
                if (row.original.Code !== "") {
                  var CodeIsNotUnique = false;

                  const originalData = props.originalData;
                  for (var x = 0; x < originalData.length; x++) {
                    if (originalData[x].Code === row.original.Code) {
                      CodeIsNotUnique = true;
                    }

                    // check mitarbeiter Code and exclude current mitarbeiter
                    for (
                      var y = 0;
                      y < originalData[x].Mitarbeiter.length;
                      y++
                    ) {
                      if (original.ID === originalData[x].ID) {
                        if (y !== row.index) {
                          if (
                            originalData[x].Mitarbeiter[y].Code ===
                            row.original.Code
                          ) {
                            CodeIsNotUnique = true;
                          }
                        }
                      } else {
                        if (
                          originalData[x].Mitarbeiter[y].Code ===
                          row.original.Code
                        ) {
                          CodeIsNotUnique = true;
                        }
                      }
                    }
                  }

                  if (CodeIsNotUnique) {
                    console.log(row);
                    console.log();
                    console.log(CodeIsNotUnique);
                    enqueueSnackbar(
                      "Der Code wird schon verwendet, bitten einen Eindeutigen wählen",
                      {
                        variant: "error",
                      }
                    );
                    return;
                  }
                }

                setEdit({ ...edit, [row.index]: false });

                original.Mitarbeiter = data;
                setCompany(original).then(() => {
                  //refetch();
                });

                setNewData(false);
              };

              const enableEdit = (index: number) => {
                setEdit({ ...edit, [index]: true });
              };

              if (row.original.Name === "" && edit[row.index] !== true) {
                // new data
                enableEdit(row.index);
              }

              return (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "nowrap",
                  }}
                >
                  {edit[row.index] ? (
                    <SaveIcon
                      onClick={save}
                      style={{ cursor: "pointer" }}
                    ></SaveIcon>
                  ) : (
                    <EditIcon
                      //onClick={() => updateEdit(row.index)}
                      onClick={() => enableEdit(row.index)}
                      style={{ cursor: "pointer" }}
                    ></EditIcon>
                  )}
                  {row.original.Name !== "" ? (
                    <DeleteIcon
                      onClick={del}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    ></DeleteIcon>
                  ) : null}
                </div>
              );
            },
          },
        ],
      },
    ],
    [data, edit, enqueueSnackbar, original, refetch]
  );

  const defaultColumn = defaultColumnEmployee;
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn,
    edit,
    updateMyData,
  });

  return (
    <MaUTable {...getTableProps()}>
      <TableHead className={classes.borderHead}>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

// Create an editable cell renderer
const EditableCellEmployee = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  edit,
}: CellProps<EmployeeType>) => {
  console.log("e: ", edit);

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (edit[index] === true) {
    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  } else {
    return value;
  }
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumnEmployee = {
  Cell: EditableCellEmployee,
};

export default CompanyTable;
