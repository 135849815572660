import { Typography } from "@material-ui/core";
import React from "react";
import Nav from "./Nav";

const Users = () => {
  return (
    <div>
      <Nav></Nav>
      <Typography variant="h5" align="center">
        TODO
      </Typography>
    </div>
  );
};

export default Users;
