import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import {
  CompanyAuthContext,
  useCompanyAuth,
  useProvideAuth,
  useProvideUserAuth,
  UserAuthContext,
  useUserAuth,
} from "./auth";

import Company from "./Site/admin/Company";
import FormTable from "./Site/admin/FormData";
import Login from "./Site/admin/Login";
import Users from "./Site/admin/Users";

import Create from "./Site/Form";
import Welcome from "./Site/Welcome";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/">
          <Form />
        </Route>
      </Switch>
    </Router>
  );
}

function Admin() {
  const userAuth = useProvideUserAuth();
  return (
    <UserAuthContext.Provider value={userAuth}>
      <Switch>
        <PrivateAdminRoute path="/admin/data">
          <FormTable />
        </PrivateAdminRoute>
        <PrivateAdminRoute path="/admin/users">
          <Users />
        </PrivateAdminRoute>
        <PrivateAdminRoute path="/admin/company">
          <Company />
        </PrivateAdminRoute>
        <Route exact path="/admin">
          <Login />
        </Route>
      </Switch>
    </UserAuthContext.Provider>
  );
}

function Form() {
  const auth = useProvideAuth();
  return (
    <CompanyAuthContext.Provider value={auth}>
      <Switch>
        <PrivateRoute path="/form">
          <Create />
        </PrivateRoute>
        <Route path="/">
          <Welcome />
        </Route>
      </Switch>
    </CompanyAuthContext.Provider>
  );
}

function PrivateRoute<T extends RouteProps = RouteProps>({
  children,
  ...rest
}: T) {
  const auth = useCompanyAuth();
  console.log(auth?.company);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth?.company !== null ? children : <Redirect to={{ pathname: "/" }} />
      }
    />
  );
}

function PrivateAdminRoute<T extends RouteProps = RouteProps>({
  children,
  ...rest
}: T) {
  const auth = useUserAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth?.User !== null ? (
          children
        ) : (
          <Redirect to={{ pathname: "/admin" }} />
        )
      }
    />
  );
}

export default App;
