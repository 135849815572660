import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Control, Controller, FieldErrors, useForm } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Fab,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DelIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import { CompanyType, submitData } from "../api";
import { useHistory } from "react-router-dom";
import { useCompanyAuth } from "../auth";
import { FormImageInput } from "./Dropzone";

import { blue } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { RadioProps } from "@material-ui/core/Radio";

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  group: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
  },
  nr: {
    width: "30%",
    marginLeft: "10px",
  },
  plz: {
    width: "35%",
    marginRight: "10px",
  },
  marginNormal: {
    marginTop: "16px",
    marginBottom: "8px",
  },
  RadioSeconLevel: {},
}));

const RadioSeconLevel = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600],
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export interface ServiceFormData {
  Bemerkungen: string;
  Mitarbeiter: {
    Name: string;
    Mobile: string;
    EMail: string;
  };
  MitarbeiterID: number;
  Nr: string;
  Ort: string;
  PLZ: string;
  Strasse: string;
  Termin: string;
  Arbeitsauftrag: {
    Etage: string;
    WHG: string;
    Raumbezeichnung: string;
    Arbeitsausführung: string;
    Andere: string;
    Bild: string[];
    PlattenInfo: string;
    Plattenformat: string;
  }[];
  Zugang: string;
  ZugangsMA: string;
  MHName: string;
  MHTelefon: string;
  Abholen: string;
  Andere: string;
  Firma: CompanyType;
  Timestamp: string;
}

export default function Create() {
  let history = useHistory();
  const classes = useStyles();
  const CompanyAuth = useCompanyAuth();
  const { register, watch, handleSubmit, control, errors, reset } = useForm();
  const [submitErr, setSubmitErr] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitDataSave, setSubmitDataSave] = useState<ServiceFormData>();

  const [arbeitCounter, setArbeitCounter] = useState(1);

  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const accordionHandleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  console.log(errors);

  const company = CompanyAuth?.company;

  if (CompanyAuth === null || company === null || company === undefined) {
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography variant="h4">Serviceportal</Typography>
          <Alert
            severity="error"
            variant="filled"
            className={classes.marginNormal}
          >
            <AlertTitle>Error</AlertTitle>
            Es ist ein Fehler aufgetreten. Bitte versuchen sie sich erneut
            anzumelden. Falls die diese Fehlermeldung mehrfach sehen, wenden sie
            sich per E-Mail an office@dietschweiler-ag.ch
          </Alert>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.marginNormal}
            onClick={() => history.push("/")}
          >
            Anmelden
          </Button>
        </div>
      </Container>
    );
  }

  const onSubmit = (data: ServiceFormData) => {
    setSubmitDataSave(data); //save for retry

    if (company.Mitarbeiter.length === 0) {
      data.Mitarbeiter.Name = company.Ansprechpartner;
      data.Mitarbeiter.Mobile = company.Telefon;
      data.Mitarbeiter.EMail = company.EMail;
    } else {
      data.Mitarbeiter = company.Mitarbeiter[data.MitarbeiterID];
    }

    if (data.Zugang === "MA") {
      data.ZugangsMA = data.Mitarbeiter.Name;
    } else if (data.Zugang === "Abholen") {
      data.Abholen = company.Adresse + ", " + company.PLZ + " " + company.Ort;
    }

    data.Firma = {
      Name: company.Name,
      Ansprechpartner: company.Ansprechpartner,
      Adresse: company.Adresse,
      Ort: company.Ort,
      PLZ: company.PLZ,
      Telefon: company.Telefon,
      EMail: company.EMail,
      ID: company.ID,
    } as CompanyType;

    console.log(data);

    submitData(data)
      .then(() => {
        console.log("reseting Form");
        reset();
        setSubmitSuccess(true);
      })
      .catch((err) => {
        console.log(err.message);
        setSubmitErr(true);
      });
  };

  if (submitErr) {
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography variant="h4">Serviceportal</Typography>
          <Alert
            severity="error"
            variant="filled"
            className={classes.marginNormal}
          >
            <AlertTitle>Error</AlertTitle>
            Es ist ein Fehler aufgetreten. Bitte stellen sie sicher, das sie
            Internet haben und versuchen sie es nochmals. Falls die diese
            Fehlermeldung mehrfach sehen, wenden sie sich per E-Mail an
            office@dietschweiler-ag.ch
          </Alert>
          {/* <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.marginNormal}
            onClick={() => setSubmitErr(false)}
          >
            Nochmals probieren
          </Button> */}
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.marginNormal}
            onClick={() => {
              if (submitDataSave !== undefined) {
                setSubmitErr(false);
                onSubmit(submitDataSave);
              }
            }}
          >
            Nochmals Senden
          </Button>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            className={classes.marginNormal}
            onClick={() => setSubmitErr(false)}
          >
            Neuer Auftrag erfassen
          </Button>
        </div>
      </Container>
    );
  }

  if (submitSuccess) {
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography variant="h4">Serviceportal</Typography>
          <Alert
            severity="success"
            variant="filled"
            className={classes.marginNormal}
          >
            <AlertTitle>Erfolg</AlertTitle>
            Der Auftrag wurde erfolgreich erfasst
          </Alert>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.marginNormal}
            onClick={() => setSubmitSuccess(false)}
          >
            Neuer Auftrag erfassen
          </Button>
        </div>
      </Container>
    );
  }

  const watchMitarbeiter = watch("MitarbeiterID");
  const watchZugang = watch("Zugang");
  // const watchBild = watch("Bild");

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography variant="h4">Serviceportal</Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="h5">Firma</Typography>
          <Typography variant="body2" className={classes.marginNormal}>
            {company.Name}
            <br />
            {company.Adresse}
            <br />
            {company.PLZ + " " + company.Ort}
          </Typography>

          <FormControl
            variant="outlined"
            error={errors.MitarbeiterID !== undefined}
            required
            fullWidth
            margin="normal"
          >
            <InputLabel id="MitarbeiterID">Mitarbeiter</InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <Select
                  labelId="Mitarbeiter"
                  label="Mitarbeiter"
                  name="Mitarbeiter"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                >
                  {company.Mitarbeiter.map((m, i) => (
                    <MenuItem key={m.Name} value={i}>
                      {m.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="MitarbeiterID"
              control={control}
              rules={{ required: "Dies ist ein Pflichtfeld" }}
              defaultValue={
                // company.Mitarbeiter.length === 1
                //   ? company.Mitarbeiter[0].Name
                //   : ""
                0
              }
            />
            <FormHelperText>{errors.MitarbeiterID?.message}</FormHelperText>
          </FormControl>
          <Typography variant="h5" className={classes.marginNormal}>
            Objektangaben
          </Typography>
          <div className={classes.group}>
            <FormInput
              name="Strasse"
              label="Strasse"
              required="Dies ist ein Pflichtfeld"
              errors={errors}
              control={control}
              fullWidth
            />
            <FormInput
              name="Nr"
              label="Nr"
              required="Pflichtfeld"
              errors={errors}
              control={control}
              fullWidth
              className={classes.nr}
            />
          </div>
          <div className={classes.group}>
            <FormInput
              name="PLZ"
              label="PLZ"
              required="Pflichtfeld"
              errors={errors}
              control={control}
              fullWidth
              className={classes.plz}
            />
            <FormInput
              name="Ort"
              label="Ort"
              required="Dies ist ein Pflichtfeld"
              errors={errors}
              control={control}
              fullWidth
            />
          </div>

          {createArrayWithNumbers(arbeitCounter).map((index) => {
            const Arbeitsauftrag = `Arbeitsauftrag[${index}]`;
            return (
              <>
                <Typography variant="h5" className={classes.marginNormal}>
                  {`Arbeitsauftrag ${index + 1}`}
                </Typography>

                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.marginNormal}
                  error={errors.Arbeitsauftrag?.[index]?.Etage !== undefined}
                  required
                >
                  <InputLabel id={`${Arbeitsauftrag}.Etage`}>Etage</InputLabel>
                  <Controller
                    render={({ onChange, onBlur, value, ref }) => (
                      <Select
                        labelId={`${Arbeitsauftrag}.Etage`}
                        label="Etage"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                      >
                        <MenuItem value="EG">EG</MenuItem>
                        <MenuItem value="1.OG">1.OG</MenuItem>
                        <MenuItem value="2.OG">2.OG</MenuItem>
                        <MenuItem value="3.OG">3.OG</MenuItem>
                        <MenuItem value="4.OG">4.OG</MenuItem>
                        <MenuItem value="5.OG">5.OG</MenuItem>
                        <MenuItem value="6.OG">6.OG</MenuItem>
                        <MenuItem value="7.OG">7.OG</MenuItem>
                        <MenuItem value="8.OG">8.OG</MenuItem>
                        <MenuItem value="9.OG">9.OG</MenuItem>
                        <MenuItem value="10.OG">10.OG</MenuItem>
                        <MenuItem value="Attika">Attika</MenuItem>
                        <MenuItem value="UG1">UG1</MenuItem>
                        <MenuItem value="UG2">UG2</MenuItem>
                      </Select>
                    )}
                    name={`${Arbeitsauftrag}.Etage`}
                    control={control}
                    rules={{ required: "Dies ist ein Pflichtfeld" }}
                    defaultValue=""
                  />
                  <FormHelperText>
                    {errors.Arbeitsauftrag?.[index]?.Etage?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.marginNormal}
                  error={errors.Arbeitsauftrag?.[index]?.WHG !== undefined}
                  required
                >
                  <InputLabel id={`${Arbeitsauftrag}.WHG`}>WHG</InputLabel>
                  <Controller
                    render={({ onChange, onBlur, value, ref }) => (
                      <Select
                        labelId={`${Arbeitsauftrag}.WHG`}
                        label="WHG"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                      >
                        <MenuItem value="rechts">rechts</MenuItem>
                        <MenuItem value="mitte rechts">mitte rechts</MenuItem>
                        <MenuItem value="mitte">mitte</MenuItem>
                        <MenuItem value="mitte links">mitte links</MenuItem>
                        <MenuItem value="links">links</MenuItem>
                      </Select>
                    )}
                    name={`${Arbeitsauftrag}.WHG`}
                    control={control}
                    rules={{ required: "Dies ist ein Pflichtfeld" }}
                    defaultValue=""
                  />
                  <FormHelperText>
                    {errors.Arbeitsauftrag?.[index]?.WHG?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.marginNormal}
                  error={
                    errors.Arbeitsauftrag?.[index]?.Raumbezeichnung !==
                    undefined
                  }
                  required
                >
                  <InputLabel id={`${Arbeitsauftrag}.Raumbezeichnung`}>
                    Raumbezeichnung
                  </InputLabel>
                  <Controller
                    render={({ onChange, onBlur, value, ref }) => (
                      <Select
                        labelId={`${Arbeitsauftrag}.Raumbezeichnung`}
                        label="Raumbezeichnung"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                      >
                        <MenuItem value="Wohnen">Wohnen</MenuItem>
                        <MenuItem value="Küche">Küche</MenuItem>
                        <MenuItem value="Zimmer">Zimmer</MenuItem>
                        <MenuItem value="Bad/WC">Bad/WC</MenuItem>
                        <MenuItem value="Dusche/WC">Dusche/WC</MenuItem>
                        <MenuItem value="Korridor">Korridor</MenuItem>
                        <MenuItem value="Büro">Büro</MenuItem>
                        <MenuItem value="Reduit">Reduit</MenuItem>
                        <MenuItem value="Sauna">Sauna</MenuItem>
                        <MenuItem value="Waschraum">Waschraum</MenuItem>
                        <MenuItem value="Technik">Technik</MenuItem>
                        <MenuItem value="Keller">Keller</MenuItem>
                        <MenuItem value="Lift">Lift</MenuItem>
                        <MenuItem value="Treppenhaus">Treppenhaus</MenuItem>
                        <MenuItem value="Garage">Garage</MenuItem>
                        <MenuItem value="Tiefgarage">Tiefgarage</MenuItem>
                        <MenuItem value="Wintergarten">Wintergarten</MenuItem>
                        <MenuItem value="Balkon">Balkon</MenuItem>
                        <MenuItem value="Terrasse">Terrasse</MenuItem>
                        <MenuItem value="Fassade">Fassade</MenuItem>
                        <MenuItem value="TG-Einfahrt">TG-Einfahrt</MenuItem>
                      </Select>
                    )}
                    name={`${Arbeitsauftrag}.Raumbezeichnung`}
                    control={control}
                    rules={{ required: "Dies ist ein Pflichtfeld" }}
                    defaultValue=""
                  />
                  <FormHelperText>
                    {errors.Arbeitsauftrag?.[index]?.Raumbezeichnung?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.marginNormal}
                  error={
                    errors.Arbeitsauftrag?.[index]?.Arbeitsausführung !==
                    undefined
                  }
                  required
                >
                  <FormLabel
                    component="legend"
                    style={{ marginBottom: "10px" }}
                  >
                    Arbeitsausführung:
                  </FormLabel>
                  <Controller
                    render={({ onChange, onBlur, value, ref }) => (
                      <RadioGroup
                        name={`${Arbeitsauftrag}.Arbeitsausführung`}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                      >
                        <Accordion
                          expanded={expanded === "Plattenbeläge"}
                          onChange={accordionHandleChange("Plattenbeläge")}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Plattenbeläge</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="MuiFormGroup-root">
                            <FormControlLabel
                              value="Platten auswechseln"
                              control={<Radio />}
                              label="Platten auswechseln ..."
                            />
                            {value === "Platten auswechseln" ? (
                              <div style={{ marginLeft: "30px" }}>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  // className={classes.marginNormal}
                                  error={
                                    errors.Arbeitsauftrag?.[index]
                                      ?.PlattenInfo !== undefined
                                  }
                                  required
                                >
                                  {/* <FormLabel component="legend">Zusatz:</FormLabel> */}
                                  <Controller
                                    render={({
                                      onChange,
                                      onBlur,
                                      value,
                                      ref,
                                    }) => (
                                      <RadioGroup
                                        name="PlattenInfo"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        ref={ref}
                                        className={classes.RadioSeconLevel}
                                      >
                                        <FormControlLabel
                                          value="Platten vorhanden"
                                          control={<RadioSeconLevel />}
                                          label="Platten bauseits vorhanden"
                                        />
                                        <FormControlLabel
                                          value="Platenformat"
                                          control={<RadioSeconLevel />}
                                          label="Plattenformat ..."
                                        />
                                        {value === "Platenformat" ? (
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            id="Plattenformat"
                                            key="Plattenformat"
                                            label="Plattenformat"
                                            name={`${Arbeitsauftrag}.Plattenformat`}
                                            inputRef={register()}
                                          />
                                        ) : null}
                                      </RadioGroup>
                                    )}
                                    name={`${Arbeitsauftrag}.PlattenInfo`}
                                    control={control}
                                    rules={{
                                      required: "Dies ist ein Pflichtfeld",
                                    }}
                                    defaultValue=""
                                  />
                                  <FormHelperText>
                                    {
                                      errors.Arbeitsauftrag?.[index]
                                        ?.PlattenInfo?.message
                                    }
                                  </FormHelperText>
                                </FormControl>
                              </div>
                            ) : null}
                            <FormControlLabel
                              value="Zementfugen ersetzen"
                              control={<Radio />}
                              label="Zementfugen ersetzen"
                            />
                            <FormControlLabel
                              value="Kittfugen auswechseln"
                              control={<Radio />}
                              label="Kittfugen auswechseln"
                            />
                            <FormControlLabel
                              value="Plattenbelag auffrischen"
                              control={<Radio />}
                              label="Plattenbelag auffrischen"
                            />
                            <FormControlLabel
                              value="Wandsockel befestigen"
                              control={<Radio />}
                              label="Wandsockel befestigen"
                            />

                            <FormControlLabel
                              value="Plattenkosmetik"
                              control={<Radio />}
                              label="Plattenkosmetik"
                            />
                            <FormControlLabel
                              value="Abdichtung erstellen"
                              control={<Radio />}
                              label="Abdichtung erstellen"
                            />
                            <FormControlLabel
                              value="Plattenbeläge - Andere"
                              control={<Radio />}
                              label="Andere ..."
                            />
                            {value === "Plattenbeläge - Andere" ? (
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="AndereArbeit"
                                key="AndereArbeit"
                                label="Plattenbeläge - Andere"
                                name={`${Arbeitsauftrag}.Andere`}
                                inputRef={register({
                                  required: "Dies ist ein Pflichtfeld",
                                })}
                                error={
                                  errors.Arbeitsauftrag?.[index]?.Andere !==
                                  undefined
                                }
                                helperText={
                                  errors.Arbeitsauftrag?.[index]?.Andere
                                    ?.message
                                }
                                InputLabelProps={{
                                  className: "required-label",
                                  required: true,
                                }}
                              />
                            ) : null}
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "Bodenbeläge"}
                          onChange={accordionHandleChange("Bodenbeläge")}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Bodenbeläge</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="MuiFormGroup-root">
                            <FormControlLabel
                              value="Parkett ausbessern"
                              control={<Radio />}
                              label="Parkett ausbessern"
                            />
                            <FormControlLabel
                              value="Parkett schleifen u. versiegeln"
                              control={<Radio />}
                              label="Parkett schleifen u. versiegeln"
                            />
                            <FormControlLabel
                              value="Sockelleisten befestigen"
                              control={<Radio />}
                              label="Sockelleisten befestigen"
                            />
                            <FormControlLabel
                              value="Kittfugen auswechseln"
                              control={<Radio />}
                              label="Kittfugen auswechseln"
                            />
                            <FormControlLabel
                              value="Parkett ersetzen"
                              control={<Radio />}
                              label="Parkett ersetzen"
                            />
                            <FormControlLabel
                              value="Bodenbeläge - Andere"
                              control={<Radio />}
                              label="Andere ..."
                            />
                            {value === "Bodenbeläge - Andere" ? (
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="AndereArbeit"
                                key="AndereArbeit"
                                label="Bodenbeläge - Andere"
                                name={`${Arbeitsauftrag}.Andere`}
                                inputRef={register({
                                  required: "Dies ist ein Pflichtfeld",
                                })}
                                error={
                                  errors.Arbeitsauftrag?.[index]?.Andere !==
                                  undefined
                                }
                                helperText={
                                  errors.Arbeitsauftrag?.[index]?.Andere
                                    ?.message
                                }
                                InputLabelProps={{
                                  className: "required-label",
                                  required: true,
                                }}
                              />
                            ) : null}
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "Küchenservice"}
                          onChange={accordionHandleChange("Küchenservice")}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Küchenservice</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="MuiFormGroup-root">
                            <FormControlLabel
                              value="Türbänder einstellen"
                              control={<Radio />}
                              label="Türbänder einstellen"
                            />
                            <FormControlLabel
                              value="Türbänder ersetzen"
                              control={<Radio />}
                              label="Türbänder ersetzen"
                            />
                            <FormControlLabel
                              value="Schubladen einstellen"
                              control={<Radio />}
                              label="Schubladen einstellen"
                            />
                            <FormControlLabel
                              value="allgemeine Einstellarbeiten"
                              control={<Radio />}
                              label="allgemeine Einstellarbeiten"
                            />
                            <FormControlLabel
                              value="Küchenfront reparieren"
                              control={<Radio />}
                              label="Küchenfront reparieren"
                            />
                            <FormControlLabel
                              value="Kittfugen ersetzen"
                              control={<Radio />}
                              label="Kittfugen ersetzen"
                            />
                            <FormControlLabel
                              value="Gerät ersetzen"
                              control={<Radio />}
                              label="Gerät ersetzen"
                            />
                            <FormControlLabel
                              value="Küchenservice - Andere"
                              control={<Radio />}
                              label="Andere ..."
                            />
                            {value === "Küchenservice - Andere" ? (
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="AndereArbeit"
                                key="AndereArbeit"
                                label="Küchenservice - Andere"
                                name={`${Arbeitsauftrag}.Andere`}
                                inputRef={register({
                                  required: "Dies ist ein Pflichtfeld",
                                })}
                                error={
                                  errors.Arbeitsauftrag?.[index]?.Andere !==
                                  undefined
                                }
                                helperText={
                                  errors.Arbeitsauftrag?.[index]?.Andere
                                    ?.message
                                }
                                InputLabelProps={{
                                  className: "required-label",
                                  required: true,
                                }}
                              />
                            ) : null}
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "Glasservice"}
                          onChange={accordionHandleChange("Glasservice")}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Glasservice</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="MuiFormGroup-root">
                            <FormControlLabel
                              value="Glas ersetzen"
                              control={<Radio />}
                              label="Glas ersetzen"
                            />
                            <FormControlLabel
                              value="Kittfugen wechseln"
                              control={<Radio />}
                              label="Kittfugen wechseln"
                            />
                            <FormControlLabel
                              value="Glasservice - Andere"
                              control={<Radio />}
                              label="Andere ..."
                            />
                            {value === "Glasservice - Andere" ? (
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="AndereArbeit"
                                key="AndereArbeit"
                                label="Glasservice - Andere"
                                name={`${Arbeitsauftrag}.Andere`}
                                inputRef={register({
                                  required: "Dies ist ein Pflichtfeld",
                                })}
                                error={
                                  errors.Arbeitsauftrag?.[index]?.Andere !==
                                  undefined
                                }
                                helperText={
                                  errors.Arbeitsauftrag?.[index]?.Andere
                                    ?.message
                                }
                                InputLabelProps={{
                                  className: "required-label",
                                  required: true,
                                }}
                              />
                            ) : null}
                          </AccordionDetails>
                        </Accordion>
                      </RadioGroup>
                    )}
                    name={`${Arbeitsauftrag}.Arbeitsausführung`}
                    control={control}
                    rules={{ required: "Dies ist ein Pflichtfeld" }}
                    defaultValue=""
                  />
                  <FormHelperText>
                    {errors.Arbeitsauftrag?.[index]?.Arbeitsausführung?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.marginNormal}
                  error={errors.Arbeitsauftrag?.[index]?.Bild !== undefined}
                  required
                >
                  <FormLabel component="legend">Bilder hochladen:</FormLabel>
                  <Controller
                    name={`${Arbeitsauftrag}.Bild`}
                    control={control}
                    defaultValue={[]}
                    // rules={{ required: "Dies ist ein Pflichtfeld" }}
                    render={({ onChange }) => (
                      <FormImageInput onChange={onChange} />
                    )}
                    rules={{
                      required: "Bitte laden sie ein Bild hoch",
                      validate: (value: string[]) =>
                        Array.isArray(value) && value.length > 0
                          ? true
                          : "Bitte laden sie ein Bild hoch",
                    }}
                  />
                  <FormHelperText>
                    {errors.Arbeitsauftrag?.[index]?.Bild?.message}
                  </FormHelperText>
                </FormControl>
              </>
            );
          })}

          <Box display="flex" flexDirection="column">
            <div>
              {arbeitCounter >= 2 ? (
                <Fab
                  color="secondary"
                  //size="small"
                  component="div"
                  aria-label="add"
                  variant="extended"
                  className={classes.marginNormal}
                  onClick={() => setArbeitCounter(arbeitCounter - 1)}
                  style={{ marginTop: "32px" }}
                >
                  <DelIcon />
                  Löschen
                </Fab>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Fab
                color="primary"
                //size="small"
                component="div"
                aria-label="add"
                variant="extended"
                className={classes.marginNormal}
                onClick={() => setArbeitCounter(arbeitCounter + 1)}
                //style={{ marginTop: "32px" }}
              >
                <AddIcon />
                Arbeitsauftrag hinzufügen
              </Fab>
            </div>
          </Box>

          <Typography variant="h5" className={classes.marginNormal}>
            Weiteres
          </Typography>
          <FormControl
            component="fieldset"
            className={classes.marginNormal}
            error={errors.Termin !== undefined}
            required
          >
            <FormLabel component="legend">Termin:</FormLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <RadioGroup
                  name="Termin"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                >
                  <FormControlLabel
                    value="Express"
                    control={<Radio />}
                    label="Express / innerhalb 72h"
                  />
                  <FormControlLabel
                    value="Dietsch73"
                    control={<Radio />}
                    label="Dietsch73 / bis 10 Arbeitstage"
                  />
                </RadioGroup>
              )}
              name="Termin"
              control={control}
              rules={{ required: "Dies ist ein Pflichtfeld" }}
              // defaultValue="Dietsch73"
              defaultValue=""
            />
            <FormHelperText>{errors.Termin?.message}</FormHelperText>
          </FormControl>

          <FormControl
            component="fieldset"
            className={classes.marginNormal}
            error={errors.Zugang !== undefined}
            required
          >
            <FormLabel component="legend">Zugang:</FormLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <RadioGroup
                  name="Zugang"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                >
                  <FormControlLabel
                    value="MA"
                    control={<Radio />}
                    label="Wird durch uns geöffnet ... "
                  />
                  {value === "MA" ? (
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      margin="normal"
                      id="ZugangsMA"
                      key="ZugangsMA"
                      label="Mitarbeiter"
                      name="ZugangsMA"
                      inputRef={register()}
                      value={
                        watchMitarbeiter === undefined
                          ? ""
                          : company.Mitarbeiter[watchMitarbeiter].Name
                      }
                    />
                  ) : null}
                  <FormControlLabel
                    value="MieterHauswart"
                    control={<Radio />}
                    label="Mieter / Hauswart kontaktieren ..."
                  />
                  {value === "MieterHauswart" ? (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="MHName"
                        key="MHName"
                        label="Name des Mieter / Hauswart"
                        name="MHName"
                        inputRef={register({
                          required: "Dies ist ein Pflichtfeld",
                        })}
                        error={errors.MHName !== undefined}
                        helperText={errors.MHName?.message}
                        InputLabelProps={{
                          className: "required-label",
                          required: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="MHTelefon"
                        key="MHTelefon"
                        label="Telefonnummer des Mieter / Hauswart"
                        name="MHTelefon"
                        inputRef={register({
                          required: "Dies ist ein Pflichtfeld",
                        })}
                        error={errors.MHTelefon !== undefined}
                        helperText={errors.MHTelefon?.message}
                        InputLabelProps={{
                          className: "required-label",
                          required: true,
                        }}
                      />
                    </>
                  ) : null}
                  <FormControlLabel
                    value="Abholen"
                    control={<Radio />}
                    label="Schlüssel kann bei uns abgeholt werden"
                  />
                  {value === "Abholen" ? (
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      margin="normal"
                      id="Abholen"
                      key="Abholen"
                      label="Schlüssel Abholen bei"
                      name="Abholen"
                      inputRef={register()}
                      defaultValue={
                        company.Adresse + ", " + company.PLZ + " " + company.Ort
                      }
                    />
                  ) : null}
                  <FormControlLabel
                    value="Andere"
                    control={<Radio />}
                    label="Andere ..."
                  />
                  {value === "Andere" ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      id="Andere"
                      key="Andere"
                      label="Anderer Zugang"
                      name="Andere"
                      inputRef={register({
                        required: "Dies ist ein Pflichtfeld",
                      })}
                      error={errors.Andere !== undefined}
                      helperText={errors.Andere?.message}
                      InputLabelProps={{
                        className: "required-label",
                        required: true,
                      }}
                    />
                  ) : null}
                </RadioGroup>
              )}
              name="Zugang"
              control={control}
              rules={{ required: "Dies ist ein Pflichtfeld" }}
              defaultValue=""
            />
            <FormHelperText>{errors.Zugang?.message}</FormHelperText>
          </FormControl>

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="Bemerkungen"
            label="Bemerkungen"
            name="Bemerkungen"
            multiline
            rows={4}
            inputRef={register()}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Absenden
          </Button>
        </form>
      </div>
    </Container>
  );
}

function FormInput(props: {
  name: string;
  label: string;
  required?: string;
  fullWidth?: boolean;
  errors: FieldErrors;
  control: Control<Record<string, any>>;
  className?: any;
}) {
  const { name, label, required, errors, control, className } = props;
  // let isError = false;
  // let errorMessage = "";
  // if (errors && errors.hasOwnProperty(name)) {
  //   isError = true;
  //   errorMessage = errors[name].message;
  // }

  const req = required === undefined ? false : required;

  return (
    <Controller
      as={TextField}
      name={name}
      control={control}
      defaultValue=""
      label={label}
      fullWidth
      InputLabelProps={{
        className: "required-label",
        required: true,
      }}
      variant="outlined"
      margin="normal"
      error={errors[name] !== undefined}
      helperText={errors[name]?.message}
      key={name}
      rules={{
        required: req,
      }}
      className={className}
    />
  );
}

function createArrayWithNumbers(length: number) {
  return Array.from({ length }, (_, k) => k);
}
